/*Scrollbar css start from here*/
::-webkit-scrollbar {
    background-color: #fff;
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
}

::-webkit-scrollbar-button {
    display: none;
}

/*Scrollbar css end*/
body .noPadding {
    padding: 0;
}

body a {
    font-weight: normal !important;
}

body .headerHolder {
    display: flex;
    background: #eaedf1;
    justify-content: space-between;
    padding: 0 20px;
}

body .searchInput {
    padding: 4px 0;
}

body .squareIconButton {
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 6px;
}
body .fixedBox { height: 75vh; overflow-y: auto;}
body .formLabel { font-size: 11px; margin-bottom: 8px; color: #808080; text-transform: uppercase;}
body .formRowHolder { width: 60%; padding-right: 70px; margin-bottom: 20px; position: relative;}
body .formRowHolder.fullrow { width: 100%; padding-right: 0;}
body .textInput {padding: 9px;}
body .addMoreIcon { position: absolute; top: 30px; right: 30px;}

.fileInput{display:none;}
body .menuOpened .MuiMenu-paper { overflow: visible;}
body .displayDateRange { position: absolute; font-size: 10px; top: 63px; left: 40px;}

.mb-1 {margin-bottom: 0.5rem;}
.mb-2 {margin-bottom: 1rem;}
.mb-3 {margin-bottom: 1.5rem;}
.mb-4 {margin-bottom: 2rem;}
.mt-1 {margin-top: 0.5rem;}
.mt-2 {margin-top: 1rem;}
.mt-3 {margin-top: 1.5rem;}
.mt-4 {margin-top: 2rem;}
.mr-1 {margin-right: 0.5rem;}
.mr-2 {margin-right: 1rem;}
.mr-3 {margin-right: 1.5rem;}
.mr-4 {margin-right: 2rem;}
.ml-1 {margin-left: 0.5rem;}
.ml-2 {margin-left: 1rem;}
.ml-3 {margin-left: 1.5rem;}
.ml-4 {margin-left: 2rem;}

.pb-05 {padding-bottom: 0.25rem;}
.pb-1 {padding-bottom: 0.5rem;}
.pb-2 {padding-bottom: 1rem;}
.pb-3 {padding-bottom: 1.5rem;}
.pb-4 {padding-bottom: 2rem;}
.pt-05 {padding-top: 0.25rem;}
.pt-1 {padding-top: 0.5rem;}
.pt-2 {padding-top: 1rem;}
.pt-3 {padding-top: 1.5rem;}
.pt-4 {padding-top: 2rem;}
.pr-05 {padding-right: 0.25rem;}
.pr-1 {padding-right: 0.5rem;}
.pr-2 {padding-right: 1rem;}
.pr-3 {padding-right: 1.5rem;}
.pr-4 {padding-right: 2rem;}
.pl-05 {padding-left: 0.25rem;}
.pl-1 {padding-left: 0.5rem;}
.pl-2 {padding-left: 1rem;}
.pl-3 {padding-left: 1.5rem;}
.pl-4 {padding-left: 2rem;}


/********* new style *********/
.positionRel {position: relative;}
.overflowVisible{overflow: visible;}
.textAlignCenter {text-align: center;}
.w-100 {width: 100%;}
.w-50 {width: 50%;}
.w-40 {width: 40%;}
.w-30 {width: 30%;}
.w-25 {width: 25%;}
.displayFlex {display: flex;}
.displayTable {display: table;}
.displayTableRow {display: table-row;}
.displayTableCell {display: table-cell;}
.alignItemCenter {align-items: center;}
.justifyContentBetween {justify-content: space-between;}
.footerBox {
    padding: 8px 20px; 
    text-align: center;
    border-top: 1px solid #eaedf1;
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
}
.footerContent {padding-left: 200px;}
.appSubmissionLinkB {
    border: 1px solid #eaedf1;
    border-radius: 4px;
    padding: 1.5rem 1rem;
    background-color: #eaedf1;
    /* color: #0d4689; */
    transition: all 0.3s;
    cursor: pointer;
}
.appSubmissionLinkB:hover {
    box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
    color: #0d4689;
    background-color: #fff;
}
.appSubLinkIcon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    /* color: #0d4689; */
}
.popupTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.uploadBox {
    border:1px solid #eaedf1; 
    border-radius: 4px; 
    text-align: center; 
    padding: 1.5rem; 
    cursor: pointer;
    background-color: #eaedf1;
    transition: all 0.3s;
    height: 100%;
}
.uploadBox:hover, .uploadBox:focus{
    box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
    color: #0d4689;
    background-color: #fff;
}
.upladIcon {
    margin-bottom: 0.5rem; 
    font-size: 2.5rem;
}

.uploadPreview {
    margin-bottom: 1.5rem;
}

.downloadBtn {
    padding-top: 1rem; padding-bottom: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.downloadIcon {
    margin-bottom: 0.5rem; 
    font-size: 2.5rem;
}

.cardDis {
    /* border:1px solid #eaedf1; */
    border-radius: 1rem;
    padding: 1rem 1rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
}

.disIcon {
    font-size: 1.25rem;
    color: #0d4689;
}
/* .headingBig {color: #0d4689; font-weight: 500;} */
.disIconOuter {    
    padding: 0.75rem;
    border-radius: 2rem;
    display: inline-flex; 
    align-items: center; 
    justify-content: center; 
    margin-right: 1rem;
}
.cardColor1 {background-color: #ecfefd;}
.cardColor1 .disIconOuter {background-color: rgba(40, 151, 146, 0.2);}
.cardColor3 {background-color: #f2e8ff;}
.cardColor2 .disIconOuter {background-color: rgba(131, 103, 168, 0.2);}
.cardColor2 {background-color: #dbeeff;}
.cardColor3 .disIconOuter {background-color: rgba(130, 155, 176, 0.2);}
.cardColor4 {background-color: #f7f6f9;}
.cardColor4 .disIconOuter {background-color: rgba(165, 156, 185, 0.2);}
.headingSmall {font-size: 0.8rem;}



/*********** preview report start *********/ 
  .viewLeadCard {
    border-radius:4px;
    border: 1px solid #8ddad5;
    padding: 1.25rem;
    /* height: 99%; */
    position: relative;
}
.viewLabelIcon {
    margin-top:0.2rem; 
    margin-right: 0.8rem; 
    color: #aaa;
}
.labelText {
    color: #aaa;
}
.headingLineUp {
    position: relative; 
    background-color: #fff; 
    padding-left: 0.4rem; 
    padding-right: 0.4rem; 
    top: -32px; 
    z-index: 999;
    left: 0;
    /* width: fit-content; */
    color: #0d4689;
    display: inline;
}
.headingLineUpOut {top:12px; left: 1.25rem;}

/*********** preview report end *********/ 

  .submitLeadToolbar {display: flex; justify-content: flex-end; margin-bottom: 1rem;}
  .textareaBox {width: 100%;border-radius: 8px;border-color: rgba(145, 158, 171, 0.32);}
  .textareaBox:hover {border-color: rgba(0, 0, 0, 1);}
  .textareaBox:focus {border-color: #0d4689;}
  .disbursedText {color: #00ad09; margin-left: 0.5rem;}
  .rejectedText {color: #ce0101; margin-left: 0.5rem;}
  .statusBox {padding: 0.5rem 1rem; display: inline-flex; border-radius: 0.5rem; background-color: #eef4ed; margin-bottom: 1rem;}
  .statusBoxSec {background-color: #f4eded; }

  
/*********login start here ************/
  .mainBtn {
    background-color: #2e3192; 
    color: white; 
    font-size: 14px; 
    padding: 12px 20px; 
    letter-spacing: 0.5px;
    font-weight: 500;
    border-radius: 8px;
    display: inline-flex;
    min-width: 100px;
    line-height: unset;
    text-decoration: unset;
  }
  .mainBtn:hover, .mainBtn:focus {background-color: #ec3a46;}
  
  /* .formGroup {margin-bottom: 1.25rem;} */
  /* .formControl {padding-top: 10px; padding-bottom: 10px; font-size: 14px; color: #2e3192; height:unset !important;} */
  .dissabledMenu  {color: #e9ecee;}
  /* .FormLabel {color: #999;} */

/*********login end here ************/

/******* Login New Style Start here ********/
.LoginContainer {
    /* background-image: url("../public/static/login/login-bg.jpg");  */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
  }
  .loginBox{
    margin: auto;
    box-shadow: 1.5px 3.99px 27px 0px rgb(0 0 0 / 10%);
    transition: 0.3s;
    background-color: rgba(255, 255, 255, 0.72);
    align-items: center;
    margin: 1.25rem 0;
  }
/* .loginTitle {display: flex; align-items: center; margin-bottom: 1.25rem;} */
.loginTitle img {height: 25px; margin-left: 8px; margin-top: 2px;}
.loginSubTitle {margin-bottom: 2.5rem;}
.LoginOuter {background-color: #f0f2f6;}
.loginInner {height: 100vh; width: 100%;}
.formGroupLogin {position: relative;}
.formControlFull {
  border: unset; background-color: #fafafc; padding: 1rem 1rem 1rem 3rem;
  font-size: 1rem; border: 1px solid #d8e4e4; border-radius: 8px; background-color: #fff;
}
.formControlIcon {position: absolute; left: 1rem; top: 1rem;z-index: 999; color: #8ddad5;}
.formControlFull:focus {background-color: #f0f8f7; border-color: #8ddad5;}
.loginLink {color: #8ddad5; text-decoration: unset;}
.loginLink:hover, .loginLink:focus {color: #0fd0b1; text-decoration: unset;}
.loginBtn {
    background: rgb(0,212,255);
    background: linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(141,218,213,1) 100%);
    color: white; 
    font-size: 14px; 
    padding: 12px 20px; 
    letter-spacing: 0.5px;
    font-weight: 500;
    border-radius: 8px;
    min-width: 100px;
    line-height: unset;
    text-decoration: unset;
    width: 100%;
    transition: all 0.3s;
}
.loginBtn:hover, .loginBtn:focus {
    background: rgb(141,218,213);
    background: linear-gradient(90deg, rgba(141,218,213,1) 0%, rgba(0,212,255,1) 100%);
  }
.orDivider {position: relative; text-align: center;}
.orDivider::before, .orDivider::after {
      content: ""; 
      position: absolute; 
      top: calc(50% - 0.5px);
      height: 1px; 
      width: 42%; 
      background-color: #d8e4e4;
}
.orDivider::before {left: 0;}
.orDivider::after {right: 0;}
.loginBanner {height: 100vh; width: 100%; 
background-image: url("../public/static/login/banner.png"); 
background-position: center; background-repeat: no-repeat; background-size: cover; position: relative;
display: flex; justify-content: center; align-items: center; flex-direction: column;
text-align: center; color: #fff;}
.loginBanner:after {background-color: rgba(0, 0, 0, 0.75); height: 100%; width: 100%; content: "";
left: 0; right: 0; bottom: 0; top: 0; position: absolute;z-index: 0;}
.loginBanner * {color: #fff; position: relative; z-index: 999;}
  
/******* Login New Style End here ********/

/*********** timeline **********/
.timelineB {position: relative; overflow-x: auto; align-items: flex-start;
    display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-flow:row; width:100%;}
.timelineContentB::after {position: absolute; content: ''; height:2px;  background-color: #eaedf1; top: 2.25rem; left: 0; right: 0;}
.timelineContentB {display: flex; flex-direction: column; justify-content: center; min-width:210px; text-align: center; 
    position: relative; padding: 0 1rem 1rem;}
.timelineContentB::before {position: absolute;content: ''; width: 16px; height: 16px; border-radius: 50%; 
top: 1.8rem; left: calc(50% - 8px); background-color: #eaedf1;z-index: 999;}
.timelineContentBGreen::before {background-color: #00ad09;}
.timelineContentBOrange::before {background-color: #f36900;}
.date {margin-bottom: 2rem;}
/*********** timeline **********/
.statusB {padding: 0.5rem 1rem; display: flex; border-radius: 0.5rem; background-color: #eef4ed; margin-bottom: 1.5rem;}
.mainBtnSmall {width: 75px; margin: 0 auto;}


/******** review start ********/
.cardOuter {border-radius: 0.5rem; padding: 1rem; border:1px dashed #eaedf1;}
.profileImg {height: 100px; border:1px solid #eaedf1; border-radius: 0.25rem; margin-bottom: 0.5rem;}
.circleProgress {position: relative; width: 120px; height: 120px; text-align: center; margin-left: auto; margin-right: auto;}
.circleProgress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 8px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.progressValue {position: absolute; top: calc(60px - 18px); left: calc(60px - 23px);}
.documentImg {
  border: 6px solid #f8f8f8;
  box-shadow: 0 0 20px -8px rgb(0 0 0 0/ 10%);
  border-radius: 4px;
  width: 100%;
}
/******** review end ********/


/********wizrd css start here **********/

.stepWizardOuter  {
    background: #f9f9f9;
    border-radius: 0.5rem;
  }
.stepWizardOuter .stepWizard  {
padding: 1rem;
border-radius: 0.5rem;
margin-right: 2rem;
}

.MuiStepIcon-root {
color: rgba(0, 0, 0, 0.38);
display: block;
} 

.previousBtn {background-color: #f9f9f9; color: #2e3192;}
.previousBtn:hover, .previousBtn:focus {background-color: #e9e9e9; color: #2e3192;}
.nextBtn {margin-left: auto;}
.uploadDummyImg {height: 100px; margin-bottom: 1.5rem; display: inline-block; border-radius: 8px;
border:1px solid #f0f0f0;}
.uploadCardLink {justify-content: center; align-items: center;}
.homeCardLink.uploadCardLink .linkIcon{margin-left: 3px; font-size: 21px;}
.loginOtpList{font-size: 14px;}

.docIcon {font-size: 1.25rem; color: #2e3192;}
.tableHead {background-color: #f9f9f9;}
.docApplicantName {color: #2e3192; font-size: 1rem;}


.PopupContent {min-width: 320px; width: 540px;}
.uploadPopupRow {padding: 0.5rem; background-color: #fafbfc;  border:1px solid #f0f0f0;
border-radius: 0.5rem; margin-bottom: 0.5rem;}
.uploadFilePreview {border:1px solid #f0f0f0; width: 120px; margin-right: 1rem; transition: all 0.3s;
border-radius: 0.5rem; padding: 1rem 0.5rem; text-align: center; background-color: #fafafa;}
.ImageViewIcon {font-size: 2rem;}
.uploadFilePreviewGreen {border-color: #00ad09;}
.uploadFilePreviewRed {border-color: #ce0101;}
/* .uploadFilePreview:hover {background-color: #fff; box-shadow: 0 0 20px -8px rgba(0,0,0,0.2);} */
.UploadPopupIcon {font-size: 1.25rem; margin-right: 1rem; width: 40px; height: 40px; text-align: center;
background-color: #fff; border-radius: 1.25rem; display: inline-flex; align-items: center; justify-content: center;
box-shadow:0 0 10px -2px rgb(0 0 0 / 10%);-webkit-box-shadow:0 0 10px -2px rgb(0 0 0 / 10%);}
.UploadPopupIcon img {width: 28px; display: inline;}
.UploadIcon {font-size: 28px; color: #2e3192;}
.Fileupload {margin-left: 1rem;}
.PassProtectB {width: 100%; display: flex; align-items: center; font-size: 0.75rem; flex-direction: row;}
.uploadPopupInnerRow {display: flex; align-items: center;}
.outerTableBox {border: 1px solid #f0f0f0; border-radius: 0.5rem;}
.documentUploadedBox {padding: 1rem;}
/* .formControlRemark {padding: 5px 10px;} */
.popupHeading {border-bottom: 1px solid #2e3192; margin-bottom: 1rem; display: flex; 
align-items: center; justify-content: space-between;}
.formLabel1 {margin-bottom: 0.5rem;}
.hideForView {display: none;}
.TextField {border: 1px solid #f0f0f0; border-radius: 0.5rem; padding: 0.4rem 0.75rem; background-color: #fff; font-size: 0.875rem;}
.uploadBtn {background-color: #2e3192; color: #fff; padding-left: 0.75rem; padding-right: 0.75rem;margin-left: 0.75rem;}
.uploadBtn:hover,.uploadBtn:focus {background-color: #ec3a46; }
.TextFieldGroup {border: unset;}
.FileTypeName {white-space: nowrap;}

/********wizrd css end here **********/

/********* integration card design *********/
.cardOuterGreenBorder {border: 1px dashed #8ddad5; padding: 0;}
.cardTop {padding: 1rem;}
.cardTop {display: flex; align-items: center;}
.cardIconIcon {
    /* align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgb(0 0 0 / 6%);
    color: #0f478a;
    display: flex;
    height: 80px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    width: 80px; */
    font-size: 2rem;
}

.cardIconInner {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgb(0 0 0 / 6%);
    /* color: #0f478a; */
    display: flex;
    height: 64px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    width: 64px;
    margin-right: 1rem;
}

.cardBottom {border-top: 1px dashed #8ddad5; padding: 0.1rem 0.5rem;}
.facebook {color: #3b5998;}
.instagram {color: #fccc63;}
.youtube {color: #ff0000;}
.callCenter {color: #0fd0b1;}
.cardText {font-size: 0.875rem;}